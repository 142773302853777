.padding-content{
    padding: 2em;
}

@media screen and (max-width: 767px){
    .img-header-login{
        /* display: block; */
        /* max-width: none; */
        margin: auto;
        padding-bottom: 1.5em;
    }
}
.img-header-login{
    /* max-width: 25%; */
    /* display: block; */
    margin: auto;
    padding-bottom: 1.5em;
}